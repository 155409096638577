* {
  backface-visibility: hidden;
}

.floating-container {
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 10000;

  .flexbox-container {
    background-color: var(--color-primary);
    border-radius: 50%;
    width: 50px;
    height: 50px;
  }

  .icon {
    color: var(--color-background-primary);
  }
}

.clicking-animated {
  cursor: pointer;
  transition: all 0.5s ease;
}
.clicking-animated:active {
  transform: scale(0.8);
}

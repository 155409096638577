.error-container {
  width: 100vw;
  max-width: 100vw;
  height: 100vh;
  max-height: 100vh;

  position: fixed;
  top: 0;
  left: 0;

  overflow: hidden;
  z-index: 10000;
  background-color: var(--color-background-primary);

  .close-icon {
    position: absolute;
    top: 20px;
    right: 20px;
    color: var(--color-text-quaternary);
  }

  .error-img {
    width: 50%;
    height: auto;
    max-width: 200px;
    aspect-ratio: 1;
    object-fit: cover;
    object-position: center;
  }

  .error-text {
    font-size: 1.2rem;
  }
}

.mailcard-container {
  width: 100%;
  max-width: 100%;
  height: 100px;
  background-color: var(--color-background-primary);
  margin-bottom: 3px;

  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */

  .mailcard-flexbox {
    height: 100%;
    padding: 1rem 0rem;

    .mail-nowrap-content {
      white-space: nowrap;
      overflow: hidden;
      display: inline;
      width: 100%;
    }
  }
}

.animated {
  -webkit-animation-duration: 0.5s;
  animation-duration: 0.5s;
  -webkit-animation-fill-mode: backwards;
  animation-fill-mode: backwards;
}

.clicking-animation {
  cursor: pointer;
  transition: all 0.5s ease;
}
.clicking-animation:active {
  transform: scale(0.8);
  border-radius: 8px;
}

@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;300;400;500;700;900&display=swap);
.primary-heading{font-family:'Inter', sans-serif;font-style:normal;font-weight:bold;line-height:140%;letter-spacing:-0.02em;color:var(--color-text-primary);font-size:1.125rem}

.secondary-heading{font-family:'Inter', sans-serif;font-style:normal;font-weight:bold;line-height:140%;letter-spacing:-0.02em;color:var(--color-text-secondary);font-size:1.125rem}

.tertiary-heading{font-family:'Inter', sans-serif;font-style:normal;font-weight:600;line-height:140%;letter-spacing:-0.02em;color:var(--color-text-secondary);font-size:0.875rem}

.primary-para{font-family:'Inter', sans-serif;font-style:normal;font-weight:normal;line-height:140%;letter-spacing:-0.02em;color:var(--color-text-tertiary);font-size:0.75rem}

.secondary-para{font-family:'Inter', sans-serif;font-style:normal;font-weight:600;line-height:140%;letter-spacing:-0.01em;color:var(--color-text-quaternary);font-size:0.875rem}

.highlight-text{font-family:'Inter', sans-serif;font-style:normal;font-weight:600;line-height:140%;letter-spacing:-0.01em;color:var(--color-primary);font-size:0.75rem}

.flexbox{display:flex}.flex-column{flex-direction:column}.flex-reverse-row{flex-direction:row-reverse}.flex-reverse-collumn{flex-direction:column-reverse}.flex-wrap{flex-wrap:wrap}.justify-start{justify-content:flex-start}.justify-center{justify-content:center}.justify-end{justify-content:flex-end}.justify-between{justify-content:space-between}.justify-around{justify-content:space-around}.justify-evenly{justify-content:space-evenly}.align-start{align-items:flex-start}.align-center{align-items:center}.align-end{align-items:flex-end}

.container{width:90%;max-width:600px;margin:0 auto}.container::-webkit-scrollbar{display:none}

.page-title-container{background:var(--color-background-primary);position:relative;padding:1rem 0.7rem;min-height:60px}.border-bottom{border-bottom:1px solid var(--color-text-quaternary)}.back-icon{color:var(--color-text-secondary);font-size:1rem;position:absolute;transform:translate(-50%, -50%);top:50%;left:18px}.icon{color:var(--color-text-secondary);font-size:1rem;position:absolute;transform:translate(-50%, -50%);top:50%;right:10px}

:root{--tab-width: 20%;--tab-height: 50px}.nav-container{position:relative;width:100%;overflow-x:auto;padding-bottom:0px !important}.nav-container::-webkit-scrollbar{display:none}.nav-container .nav-item{width:20%;width:var(--tab-width);min-width:20%;min-width:var(--tab-width);height:50px;height:var(--tab-height);padding:0.9rem 0.3rem;text-align:center;vertical-align:middle;transition:color 350ms ease 0s}.nav-container .nav-item.active-tab{color:var(--color-text-secondary)}.nav-container .highlight{position:absolute;bottom:0px;left:0px;width:20%;width:var(--tab-width);height:5px;background:var(--color-primary);border-top-left-radius:3px;border-top-right-radius:3px;transition:transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1);transition-delay:0.1s}.nav-container .highlight.left-0{transform:translateX(calc(0 * 20% * 5));transform:translateX(calc(0 * var(--tab-width) * 5))}.nav-container .highlight.left-1{transform:translateX(calc(1 * 20% * 5));transform:translateX(calc(1 * var(--tab-width) * 5))}.nav-container .highlight.left-2{transform:translateX(calc(2 * 20% * 5));transform:translateX(calc(2 * var(--tab-width) * 5))}.nav-container .highlight.left-3{transform:translateX(calc(3 * 20% * 5));transform:translateX(calc(3 * var(--tab-width) * 5))}.nav-container .highlight.left-4{transform:translateX(calc(4 * 20% * 5));transform:translateX(calc(4 * var(--tab-width) * 5))}

.mailcard-container{width:100%;max-width:100%;height:100px;background-color:var(--color-background-primary);margin-bottom:3px;user-select:none;-webkit-user-select:none;-khtml-user-select:none;-moz-user-select:none;-ms-user-select:none}.mailcard-container .mailcard-flexbox{height:100%;padding:1rem 0rem}.mailcard-container .mailcard-flexbox .mail-nowrap-content{white-space:nowrap;overflow:hidden;display:inline;width:100%}.animated{animation-duration:0.5s;animation-fill-mode:backwards}.clicking-animation{cursor:pointer;transition:all 0.5s ease}.clicking-animation:active{transform:scale(0.8);border-radius:8px}

*{-webkit-backface-visibility:hidden;backface-visibility:hidden}.floating-container{position:fixed;bottom:25px;right:25px;z-index:10000}.floating-container .flexbox-container{background-color:var(--color-primary);border-radius:50%;width:50px;height:50px}.floating-container .icon{color:var(--color-background-primary)}.clicking-animated{cursor:pointer;transition:all 0.5s ease}.clicking-animated:active{transform:scale(0.8)}

.loading-container{width:100vw;max-width:100vw;height:100vh;max-height:100vh;position:fixed;top:0;left:0;overflow:hidden;z-index:10000;background-color:var(--color-background-primary)}.loading-container .close-icon{position:absolute;top:20px;right:20px;color:var(--color-text-quaternary)}.loading-container .loading-img{width:40%;height:auto;max-width:200px;aspect-ratio:1;object-fit:cover;object-position:center}

.error-container{width:100vw;max-width:100vw;height:100vh;max-height:100vh;position:fixed;top:0;left:0;overflow:hidden;z-index:10000;background-color:var(--color-background-primary)}.error-container .close-icon{position:absolute;top:20px;right:20px;color:var(--color-text-quaternary)}.error-container .error-img{width:50%;height:auto;max-width:200px;aspect-ratio:1;object-fit:cover;object-position:center}.error-container .error-text{font-size:1.2rem}

.page-wrapper{width:100%;max-width:100vw;height:100%;max-height:100vh;overflow:hidden;padding-top:110px}.page-wrapper::-webkit-scrollbar{display:none}.page-wrapper .head-container{position:fixed;top:0;left:0;width:100%}.page-wrapper .loading-container{height:calc(100vh - 150px)}.page-wrapper .loading-container img{width:50%;height:auto;object-fit:cover;object-position:center}.page-wrapper .body-container{height:calc(100vh - 110px);overflow-y:auto;width:100%;background:var(--color-background-secondary)}.page-wrapper .body-container::-webkit-scrollbar{display:none !important}.page-wrapper .body-container .empty-mail-container{width:100%;height:95%}.page-wrapper .body-container .empty-mail-container .empty-mail-img{width:45%;max-width:200px;height:auto;aspect-ratio:1;object-fit:cover;object-position:center;border-radius:50%}.page-wrapper .body-container .empty-mail-container h1{margin-top:1rem}.page-wrapper .body-container .empty-mail-container .empty-mail-msg{font-size:1rem}

.add-child-margin *{margin-bottom:0.8rem}.body-container.add-child-margin{height:calc(100vh - 60px) !important}.body-container.add-child-margin p a{word-wrap:break-word}.body-container.add-child-margin .attachments-container .attachment{padding:5px 10px;margin-bottom:10px;width:100%;background:var(--color-background-secondary);border-radius:5px;font-size:0.9rem;color:var(--color-text-tertiary)}.body-container.add-child-margin .attachments-container .attachment p,.body-container.add-child-margin .attachments-container .attachment svg{margin-bottom:0}.body-container.add-child-margin .attachments-container .attachment p{margin-left:10px;color:var(--color-text-tertiary)}

*,*::before,*::after{box-sizing:border-box;-webkit-font-smoothing:antialiased}html,body,div,input,button,select,option,h1,h2,h3,h4,h5,h6,p,text{font-family:Poppins, -apple-system, sans-serif}html,body{max-width:100vw;min-height:100vh}html,body,div,span,applet,object,iframe,h1,h2,h3,h4,h5,h6,p,blockquote,pre,a,abbr,acronym,address,big,cite,code,del,dfn,em,img,ins,kbd,q,s,samp,small,strike,strong,sub,sup,tt,var,b,u,i,center,dl,dt,dd,ol,ul,li,fieldset,form,label,legend,caption,tbody,tfoot,thead,tr,th,td,article,aside,canvas,details,embed,figure,figcaption,footer,header,hgroup,menu,nav,output,ruby,section,summary,time,mark,audio,video{margin:0;padding:0;border:0;font-size:100%;vertical-align:baseline}article,aside,details,figcaption,figure,footer,header,hgroup,menu,nav,section{display:block}body{line-height:1;background:var(--color-background);scroll-behavior:smooth;overflow-x:hidden}ol,ul{list-style:none}blockquote,q{quotes:none}blockquote::before,blockquote::after,q::before,q::after{content:'';content:none}table{border-collapse:collapse;border-spacing:0}[tabindex]{outline:none !important}::-webkit-scrollbar{width:5px;display:none}::-webkit-scrollbar-track{background:var(--color-background-primary);height:100px}::-webkit-scrollbar-thumb{background:var(--color-background-secondary);border-radius:5px}html{overflow-x:hidden;--font-weight-bold: 500;--font-weight-regular: 400;--font-weight-light: 300;--font-family: 'Poppins', -apple-system, sans-serif;--reach-dialog: 1;--reach-tabs: 1;--transition-time: 350ms;--transition-type: ease;--transition-delay: 0s;--transition: all 350ms ease 0s;--max-width: 70em;--max-width-bleed: 90%;--max-width-narrow: 50em}body{--color-primary: #2f80ed;--color-text-primary: #333333;--color-text-secondary: #4f4f4f;--color-text-tertiary: #828282;--color-text-quaternary: #bdbdbd;--color-background-primary: rgb(255, 255, 255);--color-background-secondary: #eeeeee}


html {
  overflow-x: hidden;

  /* General */
  --font-weight-bold: 500;
  --font-weight-regular: 400;
  --font-weight-light: 300;
  --font-family: 'Poppins', -apple-system, sans-serif;
  --reach-dialog: 1;
  --reach-tabs: 1;

  /* Transitions */
  --transition-time: 350ms;
  --transition-type: ease;
  --transition-delay: 0s;
  --transition: all 350ms ease 0s;

  /* Widths */
  --max-width: 70em;
  --max-width-bleed: 90%;
  --max-width-narrow: 50em;
}

body {
  --color-primary: #2f80ed;

  /* Text Colors */
  --color-text-primary: #333333;
  --color-text-secondary: #4f4f4f;
  --color-text-tertiary: #828282;
  --color-text-quaternary: #bdbdbd;

  /* Background Colors */
  --color-background-primary: rgb(255, 255, 255);
  --color-background-secondary: #eeeeee;
}

.page-wrapper {
  width: 100%;
  max-width: 100vw;
  height: 100%;
  max-height: 100vh;
  overflow: hidden;
  padding-top: 110px;

  &::-webkit-scrollbar {
    display: none;
  }

  .head-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
  }

  .loading-container {
    height: calc(100vh - 150px);

    img {
      width: 50%;
      height: auto;
      object-fit: cover;
      object-position: center;
    }
  }

  .body-container {
    height: calc(100vh - 110px);
    overflow-y: auto;

    width: 100%;
    background: var(--color-background-secondary);

    &::-webkit-scrollbar {
      display: none !important;
    }

    .empty-mail-container {
      width: 100%;
      height: 95%;

      .empty-mail-img {
        width: 45%;
        max-width: 200px;
        height: auto;
        aspect-ratio: 1;
        object-fit: cover;
        object-position: center;
        border-radius: 50%;
      }

      h1 {
        margin-top: 1rem;
      }

      .empty-mail-msg {
        font-size: 1rem;
      }
    }
  }
}
